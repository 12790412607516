.upload-btns {
  height: 40px;
}
.upload-btns /deep/ .el-upload {
  height: 40px !important;
  border: none !important;
}
.el-textarea__inner {
  min-height: 12rem !important;
  resize: none;
}
.pageForm-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.left /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.formContractId .el-form-item__content {
  display: flex;
  align-items: center;
}
.formContractId .el-form-item__content .el-select {
  width: 100%;
}
.formSelect .el-select {
  width: 100%;
}
.form-item-inline {
  display: inline-block;
}
.form-item-inline .el-input {
  width: 100px;
}
